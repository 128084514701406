import React from 'react'
import Layout from 'components/layout/layout'
import OrganizationalGrant from 'components/forms/orgGrant'

export default () => {
  return (
    <Layout>
      <OrganizationalGrant />
    </Layout>
  )
}
